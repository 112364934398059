import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

const BlogPage = ({ data }) => {
  // console.log(data)
  return (
    <Layout>
      <div>
        {/* <h1>Blogs</h1>
        <h4>{data.allMarkdownRemark.totalCount} Posts</h4> */}
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <div className="post-preview">
              <p className="post-date">
                {node.frontmatter.date}
              </p>
              <Link to={node.fields.slug}>
                <h3>{node.frontmatter.title}</h3>
                <p>{node.excerpt}</p>
              </Link>
            </div>
            <hr/>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default BlogPage
